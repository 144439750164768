import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignIn from './views/SignIn'
import System from './views/System'

class App extends React.Component {
	render(){
		return(
			<Switch>
				<Route path = "/" component={SignIn} exact/>
				<Route path = "/system" component={System}/>
        	</Switch>
		);
	}
}

export default App;
