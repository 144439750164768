import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { App } from './components';
import { ErrorBoundary } from './error';
import { API } from './services'
import { APIProvider } from './context'


import store from './store/store'
const APIService = new API()

ReactDOM.render(
	<Provider store={ store }>
		<ErrorBoundary>
			<APIProvider value={ APIService }>
				<Router>
					<App/>
				</Router>
			</APIProvider>
		</ErrorBoundary>
	</Provider>,
	document.getElementById('root')
);
