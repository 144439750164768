import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { changedLoadingState, changedPageInfo } from '../../../../store/actions'
import { withAPI } from '../../../../hoc'

class SystemCollegeTeachers extends React.Component {
    state = {
        teachers: []
    }
    componentDidMount(){
        const { changedLoadingState, changedPageInfo, API, match } = this.props
        changedLoadingState(true)
        API.getCollegeTeacherList(match.params.bin)
        .then((res) => {
            if(res.code == 0)
                this.setState({teachers: res.data})
            changedLoadingState(false)
        })
        
        changedPageInfo({
            menuIndex: 2,
            title: "Преподаватели"
        })
    }

    render(){
        const { match } = this.props
        const { teachers } = this.state
        return(
            <>
                <div className="row" id="table-head">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Список преподавателей колледжа</h4>
                            </div>
                            <div className="card-content">
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>№</th>
                                                <th>Ф.И.О.</th>
                                                <th>КТП</th>
                                                <th>Дисциплины</th>
                                                <th>Время преподавания</th>
                                                <th>Последний вход</th>
                                                <th>Подробнее</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                teachers.map((value, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{(index + 1)}</td>
                                                            <td className="text-bold-500">{`${value.surname} ${value.name} ${value.lastname}`}</td>
                                                            <td>{`${value.ktp}%`}</td>
                                                            <td>{value.predmets}</td>
                                                            <td>{value.col_chas}</td>
                                                            <td>{value.last_enter}</td>
                                                            <td>
                                                                <NavLink to={`${match.url}/${value.iin}`}>
                                                                    <i className="badge-circle badge-circle-light-secondary bx bx-right-arrow-alt font-medium-1"/>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollegeTeachers))