const initialState = {
    userInfo: {},
    pageInfo: {
        menuIndex: 1,
        title: ''
    },
    loadingState: true
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case 'USER_SIGNED_IN':
            return {
                ...state,
                userInfo: action.userInfo
            }
        case 'PAGE_INFO_CHANGED':
            return {
                ...state,
                pageInfo: action.pageInfo
            }
        case 'LOADING_STATE_CHANGE':
            return {
                ...state,
                loadingState: action.loadingState
            }
        default: 
            return state;
    }
}

export default reducer;