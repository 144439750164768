import React from 'react'
import { APIConsumer } from '../context'

const withAPI = () => Wrapped => {
    return props => {
        return(
            <APIConsumer>
                {
                    (API) => {
                        return <Wrapped {...props } API={API}/>
                    }
                }
            </APIConsumer>
        );
    }
}

export default withAPI