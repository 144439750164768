import React from 'react'
import { connect } from 'react-redux'

import { changedLoadingState, changedPageInfo } from '../../../../store/actions'
import { withAPI } from '../../../../hoc'

class SystemCollegeJournal extends React.Component {
    state = {
        students: [],
        activeMonth: "",
        days: [],
        activeYear: 0
    }

    componentDidMount(){
        const { changedLoadingState, changedPageInfo, API, match } = this.props
        const { journalId, bin } = match.params
        changedLoadingState(true)
        API.getStudentsList(bin, journalId, "first")
        .then((res) => {
            if (res.code == 0){
                this.setState({
                    students: res.data, 
                    activeMonth: res.month,
                    activeYear: res.year,
                    days: res.days
                })
                console.log(res)
                changedLoadingState(false)
            }
        })
        
        changedPageInfo({
            menuIndex: 2,
            title: "Журнал"
        })
    }

    buttonClicked = (value) => {
        const { API, match } = this.props
        const { journalId, bin } = match.params
        API.getStudentsList(bin, journalId, value)
        .then((res) => {
            if (res.code == 0){
                this.setState({
                    students: res.data, 
                    activeMonth: res.month,
                    activeYear: res.year,
                    days: res.days
                })
            }
        })
    }

    render(){
        const { students, activeMonth, activeYear, days } = this.state
        return(
            <>
                <div className="row" id="table-head">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Журнал</h4>
                            </div>
                            <div className="card-content">
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>№</th>
                                                <th>Ф.И.О.</th>
                                                <th colSpan="10" className="text-center">{activeYear}</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th className="p-0 text-center" colSpan="10">
                                                    {activeMonth}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th className="p-0 px-3 pb-1" colSpan="10">
                                                    <div className="row">
                                                        <div className="col-6 d-flex justify-content-start align-items-center">
                                                            <button type="button" className="btn btn-icon rounded-circle btn-outline-light" onClick={ () => this.buttonClicked("prev") }>
                                                                <i className="bx bx-left-arrow-alt"></i>
                                                            </button>
                                                        </div>
                                                        <div className="col-6 d-flex justify-content-end align-items-center">
                                                            <button type="button" className="btn btn-icon rounded-circle btn-outline-light" onClick={ () => this.buttonClicked("next") }>
                                                                <i className="bx bx-right-arrow-alt"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                {
                                                    days.map((value, index) => {
                                                        return(
                                                            <th className="py-0 text-center" key={index}>{value}</th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                students.map((value, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{(index + 1)}</td>
                                                            <td className="text-bold-500">{`${value.surname} ${value.name} ${value.lastname}`}</td>
                                                            {
                                                                value.marks.map((mark, mIndex) => {
                                                                    return(
                                                                        <td className="text-center" key={mIndex}>{mark}</td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollegeJournal))