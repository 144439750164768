import React from 'react'

const {
    Provider: APIProvider,
    Consumer: APIConsumer
} = React.createContext()

export {
    APIProvider,
    APIConsumer
}