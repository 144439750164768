const userSignedIn = (newUserInfo) => {
    return {
        type: 'USER_SIGNED_IN',
        userInfo: newUserInfo
    }
}

const changedPageInfo = (newPageInfo) => {
    return {
        type: 'PAGE_INFO_CHANGED',
        pageInfo: newPageInfo
    }
}

const changedLoadingState = (newLoadingState) => {
    return {
        type: 'LOADING_STATE_CHANGE',
        loadingState: newLoadingState
    }
}

export {
    userSignedIn,
    changedPageInfo,
    changedLoadingState
}