import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { changedLoadingState, changedPageInfo } from '../../../../store/actions'
import { withAPI } from '../../../../hoc'

class SystemCollegePredmets extends React.Component {
    state ={
        predmets: []
    }
    componentDidMount(){
        const { changedLoadingState, changedPageInfo, match, API } = this.props
        const { bin, iin } = match.params
        changedLoadingState(true)
        API.getTeacherPredmetList(bin, iin)
        .then((res) => {
            if( res.code == 0 )
                this.setState({predmets: res.data})
            changedLoadingState(false)
        })
        
        changedPageInfo({
            menuIndex: 2,
            title: "Список дисциплин"
        })
    }

    render(){
        const { match } = this.props
        const { predmets } = this.state
        return(
            <>
                <div className="row" id="table-head">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Список дисциплин</h4>
                            </div>
                            <div className="card-content">
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>№</th>
                                                <th>Дисциплина</th>
                                                <th>Группа</th>
                                                <th>Семестр</th>
                                                <th>Время преподавания</th>
                                                <th>Пройдено уроков</th>
                                                <th>Средний балл</th>
                                                <th>КТП</th>
                                                <th>Журнал</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                predmets.map((value, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{(index + 1)}</td>
                                                            <td className="text-bold-500">{value.predmet_name}</td>
                                                            <td>{value.class_letter}</td>
                                                            <td>{value.semestr}</td>
                                                            <td>{value.col_chasov}</td>
                                                            <td>{value.col_chasov_fakt}</td>
                                                            <td>{value.mark}</td>
                                                            <td>
                                                                <i className={`font-medium-1 bx ${value.ktp ? "bx-check" : "bx-x"}`}/>
                                                            </td>
                                                            <td>
                                                                <NavLink to={`${match.url}/journal/${value.id}`}>
                                                                    <i className="badge-circle badge-circle-light-secondary bx bxs-spreadsheet font-medium-1"/>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollegePredmets))