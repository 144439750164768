import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { withAPI } from '../../../../hoc'
import { changedLoadingState, changedPageInfo } from '../../../../store/actions'

class SystemCollegeGroupsFull extends React.Component {
    state = {
        students: []
    }

    componentDidMount(){
        const { changedLoadingState, changedPageInfo, API, match} = this.props
        changedLoadingState(true)
        API.getGroupStudentList(match.params.bin, match.params.groupId)
        .then(res => {
            if(res.code == 0){
                this.setState({students: res.data})
                changedLoadingState(false)
            }
        })
                
        
        changedPageInfo({
            menuIndex: 2,
            title: "Список студентов"
        })
    }

    render(){
        const { students } = this.state;
        return(
            <>
                <div className="row" id="table-head">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Список студентов</h4>
                            </div>
                            <div className="card-content">
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>№</th>
                                                <th>Ф.И.О.</th>
                                                <th>Средняя оценка</th>
                                                <th>Родители</th>
                                                <th>Опекун</th>
                                                <th>Тип обучения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                students.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{(index+1)}</td>
                                                        <td className="text-bold-500">{`${value.surname} ${value.name} ${value.lastname}`}</td>
                                                        <td>{value.ocenka}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <img src="/assets/images/illustration/man.svg" className={`parent__svg ${!value.ata && 'parent__svg_alpha'}`}  title="Папа"></img>
                                                                <img src="/assets/images/illustration/woman.svg" className={`parent__svg ${!value.ana && 'parent__svg_alpha'}`} title="Мама"></img>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <img src="/assets/images/illustration/man.svg" className={`parent__svg ${!value.opekun && 'parent__svg_alpha'}`}></img>
                                                        </td>
                                                        <td>{value.oplata}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollegeGroupsFull))