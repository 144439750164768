import React from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, LineChart } from 'recharts';
import { Number } from "../../../../blocks";

class College extends React.Component {
    render(){
        const { data } = this.props
        return(
            <>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Числа</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <Number number={data.count_colleges} icon="bxs-school" color="info" title="Колледжей"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <Number number={data.count_predmets} icon="bx-book" color="warning" title="Дисциплин"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <Number number={data.count_student} icon="bxs-graduation" color="danger" title="Студентов"/>
                    </div>
                    <div className="col-xl-3 col-md-4 col-sm-6">
                        <Number number={data.count_teacher} icon="bx-user-voice" color="primary" title="Преподавателей"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Зарегистрированные</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-6">
                        <Number number={data.count_reg_students} icon="bxs-graduation" color="success" title="Студентов"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                        <Number number={data.count_reg_teachers} icon="bx-user-voice" color="primary" title="Преподавателей"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                        <Number number={data.count_reg_parents} icon="bxs-group" color="warning" title="Родителей"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Оценки за 2019/2020</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                        <Number number={data.count_mark} icon="bx-font" color="danger" title="Оценок"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <Number number={data.count_mark_first_sem} icon="bxs-hourglass-top" color="info" title="Первый семестр"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-6">
                        <Number number={data.count_mark_second_sem} icon="bxs-hourglass-bottom" color="secondary" title="Второй семестр"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Сегодня на портал вошли</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={data.count_student_today_signin} icon="bxs-graduation" color="warning" title="Студентов"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                        <Number number={data.count_teacher_today_signin} icon="bx-user-voice" color="primary" title="Преподавателей"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                        <Number number={data.count_parent_today_signin} icon="bxs-group" color="success" title="Родителей"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <div className="card text-center">
                            <div className="card-content">
                                <div className="card-body">
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={data.auth}
                                        margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 10" />
                                        <XAxis dataKey="name" />
                                        <YAxis/>
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="Студент" stroke="#FDAC41" />
                                        <Line type="monotone" dataKey="Родитель" stroke="#39DA8A" />
                                        <Line type="monotone" dataKey="Преподаватель" stroke="#5A8DEE" />
                                    </LineChart>
                                </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default College