import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { changedPageInfo, changedLoadingState } from '../../../../store/actions'
import { withAPI } from '../../../../hoc' 

import { Republic, Region, College } from './blocks'

class SystemStats extends React.Component {
    state = {
        data: {}
    }
    componentDidMount(){
        const { changedPageInfo, changedLoadingState, API } = this.props
        changedLoadingState(true);
        changedPageInfo({
            menuIndex: 1,
            title: 'Статистика'
        })

        API.getStatistics()
        .then(res => {
            changedLoadingState(false)
            this.setState({data: res})
        })
    }

    loadBlock = () => {
        const { userInfo } = this.props
        const { data } = this.state
        if(userInfo.level === 1)
            return <Republic data={data}/>
        else if(userInfo.level === 2)
            return <Region data={data}/>
        else if(userInfo.level === 3)
            return <College data={data}/>
        else 
            return <div>Пусто</div>
    }

    render(){
        return(
            this.loadBlock()
        );
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedPageInfo,
    changedLoadingState
}

export default withAPI()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemStats)))