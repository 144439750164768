import React from 'react'
import { connect } from 'react-redux'
import { Legend, Tooltip, ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, BarChart, Bar } from 'recharts';
import { NavLink } from 'react-router-dom'

import { changedLoadingState, changedPageInfo } from '../../../../store/actions'
import { withAPI } from '../../../../hoc'
import { Number, PieBlock, BarBlock } from "../../../blocks";



class SystemCollege extends React.Component {
    state = {
        stat: null,
        info: null,
        bin: null,
        auth: null
    }
    componentDidMount(){
        const { changedLoadingState, changedPageInfo, API, match } = this.props
        changedLoadingState(true)
        API.getCollegeInfo(match.params.bin)
        .then(res => {
            console.log(res)
            if(res.code === 0){
                this.setState({
                    stat: res.stat,
                    info: res.info,
                    bin: res.bin,
                    auth: res.auth
                })
                changedPageInfo({
                    menuIndex: 2,
                    title: res.info.nameTrim
                })
                changedLoadingState(false)
            }
        })
    }

    usersPieAll = () => {
        const { stat } = this.state;
        return [
            {
              name: 'В системе', "Общее": (stat.students * 1) + (stat.teachers * 1) , "Студенты": stat.students, "Учители": stat.teachers,
            },
            {
              name: 'Зарегистрированных', "Общее": (stat.reg_students * 1) + (stat.reg_teachers * 1), "Студенты": stat.reg_students, "Учители": stat.reg_teachers,
            },
            {
              name: 'Сегодня вошли', "Общее": (stat.sign_today_students * 1) + (stat.sign_today_teachers * 1), "Студенты": stat.sign_today_students, "Учители": stat.sign_today_teachers,
            }
        ];
    }

    userPieData = (key) => {
        const { stat } = this.state;
        if(key === 1) {
            return [
                { name: 'Учители', value: stat.teachers }, 
                { name: 'Студенты', value: stat.students }
            ]
        }
        else if(key == 2){
            return [
                { name: 'Общее количество', value: stat.teachers + stat.students },
            ]
        }
    }

    regUserPieData = (key) => {
        const { stat } = this.state;
        if(key == 1) {
            return [
                { name: 'Учители', value: stat.reg_teachers }, 
                { name: 'Студенты', value: stat.reg_students },
                { name: 'Родители', value: stat.reg_parents }
            ]
        }
        else if(key == 2){
            return [
                { name: 'Общее количество', value: stat.reg_teachers + stat.reg_students + stat.reg_parents },
            ]
        }
    }

    todaysOnlineData = (key) => {
        const { stat } = this.state;
        if(key == 1) {
            return [
                { name: 'Учители', value: stat.sign_today_teachers }, 
                { name: 'Студенты', value: stat.sign_today_students },
                { name: 'Родители', value: stat.sign_today_parents }
            ]
        }
        else if(key == 2){
            return [
                { name: 'Общее количество', value: stat.sign_today_teachers + stat.sign_today_students + stat.sign_today_parents },
            ]
        }
    }

    lesssonsData = () => {
        const { stat } = this.state;
        return  [
            { name: 'Общее', "Количество": stat.lesson_theory_today*1 + stat.lesson_practic_today*1 + stat.lesson_fakult_today*1 },
            { name: 'Теория', "Количество": stat.lesson_theory_today },
            { name: 'Практика', "Количество": stat.lesson_practic_today },
            { name: 'Факультатив', "Количество": stat.lesson_fakult_today }
        ];
    }

    marksBarData = () => {
        const { stat } = this.state;
        return  [
            { name: 'Всего за 1 год', "Количество": stat.marks },
            { name: 'Первый семестр', "Количество": stat.marks_first },
            { name: 'Второй семестр', "Количество": stat.marks_second },
            { name: 'Сегодня', "Количество": stat.marks_today }
        ];
    }


    render(){
        const { stat, info, bin, auth } = this.state
        const { match } = this.props
        if(stat && info && bin && auth)
        return(
            <>
                <div className="row">
                    <div className="col-lg-12 col-xl-12 user-details-card">
                        <div className="card widget-user-details">
                            <div className="card-header">
                                <div className="card-title-details d-flex align-items-center">
                                    <div className="avatar bg-primary avatar-xl p-25 mr-2 ml-0">
                                        <span className="avatar-content"> { info.col_day } дн.</span>
                                    </div>
                                    <div>
                                        <h5>{ info.name }</h5>
                                        <div className="card-subtitle">{ info.country }, { info.region }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="table-responsive ps">
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="pb-0 pl-0"><strong>БИН</strong></td>
                                                    <td className="pb-0 pl-0"><strong>Директор</strong></td>
                                                    <td className="pb-0 pl-0"><strong>Адрес</strong></td>
                                                    <td className="pb-0"><strong>Телефон</strong></td>
                                                    <td className="pb-0"><strong>Почта</strong></td>
                                                    <td className="pb-0"><strong>Сайт</strong></td>
                                                </tr>
                                                <tr>
                                                    <td className="pl-0">
                                                        <div className="badge badge-light-primary text-bold-500 py-50">{ bin }</div>
                                                    </td>
                                                    <td className="pl-0">{ info.director }</td>
                                                    <td className="pl-0">{ info.adress }</td>
                                                    <td >{ info.tel }</td>
                                                    <td >{ info.email }</td>
                                                    <td >{ info.site }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-top">
                                <NavLink to={`${match.url}/teachers`} className="btn btn-outline-secondary mr-50">Преподаватели</NavLink>
                                    <NavLink to={`${match.url}/groups`} className="btn btn-outline-warning">Группы</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Статистика</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.disciplines} icon="bx-book" color="warning" title="Дисциплин"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.students} icon="bxs-graduation" color="danger" title="Студентов"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.teachers} icon="bx-user-voice" color="primary" title="Преподавателей"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Пользователи</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        <div className="card text-center">
                            <div className="card-content">
                                <div className="card-body">
                                <ResponsiveContainer width="100%" height={200}>
                                <BarChart data={this.usersPieAll()} margin={{ top: 5, right: 20, left: 20, bottom: 5}}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Общее" fill="#475F7B" />
                                    <Bar dataKey="Студенты" fill="#5A8DEE" />
                                    <Bar dataKey="Учители" fill="#00CFDD" />
                                </BarChart>
                                </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <PieBlock title="В системе" data={[this.userPieData(1), this.userPieData(2)]} colors={["#FF5B5C", "#5A8DEE"]}/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <PieBlock title="Зарегистрированных" data={[this.regUserPieData(1), this.regUserPieData(2)]} colors={["#FDAC41", "#39DA8A"]}/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <PieBlock title="Сегодня зашли" data={[this.todaysOnlineData(1), this.todaysOnlineData(2)]} colors={["#00CFDD", "#475F7B"]}/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Количество входов</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <div className="card text-center">
                            <div className="card-content">
                                <div className="card-body">
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={auth} margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 10" />
                                        <XAxis dataKey="name" />
                                        <YAxis/>
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="Студент" stroke="#FDAC41" />
                                        <Line type="monotone" dataKey="Родитель" stroke="#39DA8A" />
                                        <Line type="monotone" dataKey="Преподаватель" stroke="#5A8DEE" />
                                    </LineChart>
                                </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Оценки</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        <BarBlock data={this.marksBarData()} color="#8884d8"/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Уроки сегодня</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        <BarBlock data={this.lesssonsData()} color="#FF5B5C"/>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Домашнее задание</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-3 col-sm-12">
                        <Number number={stat.hw_total} icon="bx-spreadsheet" color="success" title="Общее количество"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-12">
                        <Number number={stat.hw_teory} icon="bx-news" color="primary" title="Теория"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-12">
                        <Number number={stat.hw_practic} icon="bxs-paint-roll" color="secondary" title="Практика"/>
                    </div>
                    <div className="col-xl-3 col-md-3 col-sm-12">
                        <Number number={stat.hw_fakult} icon="bx-folder-plus" color="warning" title="Факультатив"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Домашнее задание: Сегодня</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.hw_teory_today} icon="bx-spreadsheet" color="danger" title="Теория"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.hw_practic_today} icon="bxs-paint-roll" color="primary" title="Практика"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.hw_fakult_today} icon="bx-folder-plus" color="info" title="Факультатив"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1 mb-2">
                        <h4>Сегодняшние уроки</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.lesson_teachers_today} icon="bx-user-voice" color="info" title="Преподаватели"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.lesson_material_today} icon="bx-book" color="secondary" title="Материалы"/>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12">
                        <Number number={stat.lesson_videolink_today} icon="bxs-videos" color="success" title="Ссылки на видеоурок"/>
                    </div>
                </div>
            </>
        );
        else 
            return (
                <div>Загрузка данных</div>
            );
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollege))