import React from "react";

const Number = ({number, icon, color, title}) => {

    return (
        <div className="card text-center">
            <div className="card-content">
                <div className="card-body">
                    <div className={`badge-circle badge-circle-lg badge-circle-light-${color} mx-auto my-1`}>
                        <i className={`bx ${icon} font-medium-5`}/>
                    </div>
                    <p className="text-muted mb-0 line-ellipsis">{title}</p>
                    <h2 className="mb-0">{ number }</h2>
                </div>
            </div>
        </div>
    );
}

Number.defaultProps = {
    number: 0,
    icon: "bx-question-mark",
    color: "danger",
    title: "-"
}

export default Number