import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { withAPI } from '../../../../hoc'
import { changedLoadingState, changedPageInfo } from '../../../../store/actions'

class SystemCollegeGroups extends React.Component {
    state = {
        groups: []
    }
    componentDidMount(){
        const { changedLoadingState, changedPageInfo, API, match} = this.props
        changedLoadingState(true)
        API.getCollegeGroupList(match.params.bin)
        .then(res => {
            if(res.code === 0){
                this.setState({groups: res.data})
                changedLoadingState(false)
            }
        })
        
        changedPageInfo({
            menuIndex: 2,
            title: "Список групп колледжа"
        })
    }

    render(){
        const { groups } = this.state;
        const { match } = this.props
        return(
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Список групп колледжа</h4>
                            </div>
                            <div className="card-content">
                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>№</th>
                                                <th>Группа</th>
                                                <th>Курс</th>
                                                <th>Язык обучения</th>
                                                <th>Студенты</th>
                                                <th>Муж.</th>
                                                <th>Жен.</th>
                                                <th>Куратор</th>
                                                <th>Посмотреть</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                groups.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>
                                                        <td className="text-bold-500">{value.class_letter}</td>
                                                        <td>{value.class_number}</td>
                                                        <td>{value.lang}</td>
                                                        <td>{value.students}</td>
                                                        <td>{value.student_mancount}</td>
                                                        <td>{value.student_womcount}</td>
                                                        <td>{value.teacher}</td>
                                                        <td>
                                                            <NavLink to={`${match.url}/${value.class_id}`}>
                                                                <i className="badge-circle badge-circle-light-secondary bx bx-right-arrow-alt font-medium-1"/>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedLoadingState,
    changedPageInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withAPI()(SystemCollegeGroups))