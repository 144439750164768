import React from "react";
import {PieChart, Pie, ResponsiveContainer, Tooltip} from "recharts";

const PieBlock = ({title, data, colors}) => {
    return(
        <div className="card text-center">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">{title}</h4>
            </div>
            <div className="card-content">
                <div className="card-body">
                    <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                            <Pie data={data[0]} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill={colors[0]} />
                            <Pie data={data[1]} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill={colors[1]} label={true}/>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}

export default PieBlock