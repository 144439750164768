import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withAPI } from '../../../hoc'
import { userSignedIn } from '../../../store/actions'

import './SignIn.css'

class SignIn extends React.Component {
    state = {
        issues: {
            IIN: {
                key: false,
                message: ""
            },
            password: {
                key: false,
                message: ""
            }
        },
        inProcess: true,
        formIIN: undefined,
        formPassword: undefined,
        serverResponse: {
            code: 0,
            message: ""
        }
    }

    componentWillMount(){
        const { API, history } = this.props
        API.checkUserAuth()
        .then(res => {
            
            if(res.code !== 0) {
                this.setState({
                    inProcess: false
                })
            }
            else history.push('/system/statistics')
        })
    }

    signInClicked = () => {
        const { formIIN, formPassword, issues } = this.state
        const { API, history, userSignedIn } = this.props

        this.setState({
            serverResponse: {
                code: 0,
                message: ""
            }
        });

        if(!(/\b\d{12}\b/g.test(formIIN))){
            this.setState({
                issues: {
                    ...issues,
                    IIN: {
                        key: true,
                        message: "Не правильный формат ИИН"
                    }
                }
            })
        }
        else if(!formPassword || formPassword.length < 3) {
            this.setState({
                issues: {
                    ...issues,
                    password: {
                        key: true,
                        message: "Введите пароль"
                    },
                }
            })
        }
        else {
            this.setState({inProcess: true})
            API.signIn(formIIN, formPassword)
            .then(res => {
                if(res.code !== 0){
                    this.setState({
                        inProcess: false,
                        serverResponse: {
                            code: res.code,
                            message: res.message
                        }
                    });
                }
                else {
                    userSignedIn(res);
                    history.push('/system/college-list');
                }
            })
        }
        
    }

    onInputChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
            issues: {
                IIN: { key: false, message: "" },
                password: { key: false, message: "" }
            }
        })
    }

    render(){
        const { issues, inProcess, serverResponse } = this.state
        
        return(
            <div className = "app-content content blank-page">
		        <div className="content-overlay"/>
                <div className="content-wrapper">
			        <div className="content-header row"/>
			        <div className="content-body">
                        <section id="auth-login" className="row flexbox-container">
                            <div className="col-xl-8 col-11">
                                <div className="card bg-authentication mb-0">
                                    <div className="row m-0">
                                        <div className="col-md-6 col-12 px-0">
                                            <div className="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
                                                <div className="card-header pb-1">
                                                    <div className="card-title">
                                                        <h4 className="text-center text-bold-600 mb-2">DASH COLLEGE.EDU</h4>
                                                    </div>
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <div className="alert bg-rgba-danger alert-dismissible mb-2" style={(serverResponse.code === 0)?{ display: 'none' }:{ display: 'block' }}>
                                                            <div className="d-flex align-items-center">
                                                                <i className="bx bx-error"/>
                                                                <span>{ serverResponse.message }</span>
                                                            </div>
                                                        </div>
                                                        <div className="divider">
                                                            <div className="divider-text text-uppercase text-muted"><small>Войдите через ИИН</small></div>
                                                        </div>
                                                        <div>
                                                            <div className={`form-group ${(issues.IIN.key)?"issue":""} mb-50`}>
                                                                <label className="text-bold-600" htmlFor="inputIIN">ИИН</label>
                                                                <input name="formIIN" type="text" className="form-control" id="inputIIN" placeholder="ИИН" onChange={ this.onInputChange }/>
                                                                {
                                                                    ( issues.IIN.key ) &&  
                                                                    <div className="help-block"><ul role="alert"><li>{ issues.IIN.message }</li></ul></div>
                                                                }
                                                            </div>
                                                            <div className={`form-group ${(issues.password.key)?"issue":""}`}>
                                                                <label className="text-bold-600" htmlFor="inputPassword">Пароль</label>
                                                                <input name="formPassword" type="password" className="form-control" id="inputPassword" placeholder="Пароль" onChange={ this.onInputChange }/>
                                                                {
                                                                    (issues.password.key) &&  
                                                                    <div className="help-block"><ul role="alert"><li>{ issues.password.message }</li></ul></div>
                                                                }
                                                            </div>
                                                            <button disabled={ inProcess } onClick={ this.signInClicked } className="btn btn-primary glow w-100 position-relative">Войти<i id="icon-arrow" className="bx bx-right-arrow-alt"/></button>
                                                        </div>
                                                        <hr/>
                                                        <div className="text-center"><small className="mr-25">Для получения доступа напишите на почту <span className="danger">support@mediana.kz</span></small></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                            <div className="card-content">
                                                <img className="img-fluid" src="/assets/images/illustration/lock-screen.png" alt="branding logo"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
		        </div>
	        </div>
        );
    }
}

const mapStateToProps = ( { userInfo } ) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    userSignedIn
}

export default withAPI()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn)))