import React from 'react'

class ErrorBoundary extends React.Component {
    state = {
        error: false
    }

    componentDidCatch(){
        this.setState({
            error: true
        })
    }

    render(){
        const { error } = this.state
        const { children } = this.props
        if(error){
            return(
                <p>Error</p>
            )
        }
        else {
            return children;
        }
    }
}

export default ErrorBoundary