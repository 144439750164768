import React from 'react'
import { connect } from 'react-redux'
import { NavLink, Route, withRouter } from 'react-router-dom'

import { withAPI } from '../../../hoc'
import { userSignedIn, changedLoadingState } from '../../../store/actions'

import SystemCollegeList from './SystemCollegeList'
import SystemStats from './SystemStats'
import SystemCollege from './SystemCollege'
import SystemCollegeGroups from './SystemCollegeGroups'
import SystemCollegeGroupsFull from './SystemCollegeGroupsFull'
import SystemCollegeTeachers from './SystemCollegeTeachers'
import SystemCollegePredmets from './SystemCollegePredmets'
import SystemCollegeJournal from './SystemCollegeJournal'

class System extends React.Component {
    state = {
        profileDropdown: false
    }
    
    componentWillMount(){
        const { API, history, userSignedIn } = this.props
        
        API.checkUserAuth()
        .then(res => {
            if(res.code === 0) 
                userSignedIn(res);
            else history.push('/')
        })
    }

    showHideProfileDropdown = () => {
        const { profileDropdown } = this.state
        this.setState({
            profileDropdown: !profileDropdown
        })
    }

    signOutFromSystem = () => {
        const { API, history } = this.props
        API.signOut()
        .then(res => {
            if(res.code === 0) history.push('/');
        })
    }

    constructStatisticMenuTitle = () => {
        const { userInfo } = this.props;

        if(userInfo.level === 1)
            return "республики"
        else if(userInfo.level === 2)
            return "области"
        else if(userInfo.level === 3)
            return "колледжа"
    }

    render(){
        const { userInfo, match, pageInfo, loadingState } = this.props;
        const { profileDropdown } = this.state
        return(
            <>
                <div className={`page-loading ${ loadingState && 'show' }`}>
                    <div className="page-loading__item"></div>
                    <p>Загрузка данных...</p>
                </div>
                <div className="header-navbar-shadow"></div>
                <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top">
                    <div className="navbar-wrapper">
                        <div className="navbar-container content">
                            <div className="navbar-collapse d-flex justify-content-end" >
                                <ul className="nav navbar-nav float-right">
                                    <li className="nav-item d-none d-lg-block">
                                        <a className="nav-link nav-link-expand" style={{cursor: "pointer"}}>
                                            <i className="ficon bx bxs-analyse"></i>
                                        </a>
                                    </li>
                                    <li className="dropdown dropdown-user nav-item">
                                        <a className="dropdown-toggle nav-link dropdown-user-link" onClick={ this.showHideProfileDropdown }>
                                            <div className="user-nav d-sm-flex d-none">
                                                <span className="user-name">{`${userInfo.surname} ${userInfo.name}`}</span>
                                                <span className="user-status text-muted">Профиль пользователя</span>
                                            </div>
                                        </a>
                                        <div className={`dropdown-menu dropdown-menu-right pb-0 ${(profileDropdown) && "show"} `}>
                                            <a className="dropdown-item" onClick={ this.signOutFromSystem }>
                                                <i className="bx bx-power-off mr-50"></i> Выйти
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow expanded">
                    <div className="navbar-header expanded">
                        <ul className="nav navbar-nav flex-row">
                            <li className="nav-item mr-auto">
                                <a className="navbar-brand" href="/">
                                    <div className="brand-logo"></div>
                                    <h2 className="brand-text mb-0">Dash</h2>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="shadow-bottom"></div>
                    <div className="main-menu-content ps">
                        <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" data-icon-style="lines">
                            <li className={`nav-item ${pageInfo.menuIndex === 1 && "active"}`}>
                                <NavLink to={`${match.path}/statistics`} >
                                    <i className="bx bx-trending-up"></i>
                                    <span className="menu-title">Статистика { this.constructStatisticMenuTitle() }</span>
                                </NavLink>
                            </li>
                            <li className=" navigation-header"><span>{ userInfo.region_name}</span></li>
                            <li className={`nav-item ${pageInfo.menuIndex === 2 && "active"}`}>
                                <NavLink to={`${match.path}/college-list`} >
                                    <i className="bx bxs-briefcase-alt-2"></i>
                                    <span className="menu-title">Список колледжей</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="app-content content">
                    <div className="content-overlay"></div>
                    <div className="content-wrapper">
                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="row breadcrumbs-top">
                                    <div className="col-12">
                                        <h5 className="content-header-title float-left pr-1 mb-0">{ pageInfo.title }</h5>
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb p-0 mb-0">
                                                <li className="breadcrumb-item"><NavLink to={`${match.path}/statistics`}><i className="bx bx-home-alt"></i></NavLink>
                                                </li>
                                                <li className="breadcrumb-item active">{ pageInfo.title }
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <Route path = {`${match.path}/college-list`} component={ SystemCollegeList } exact/>
                            <Route path = {`${match.path}/statistics`} component={ SystemStats } exact/>
                            <Route path = {`${match.path}/college-list/:bin`} component={ SystemCollege } exact/>
                            <Route path = {`${match.path}/college-list/:bin/groups`} component={ SystemCollegeGroups } exact/>
                            <Route path = {`${match.path}/college-list/:bin/groups/:groupId`} component={ SystemCollegeGroupsFull } exact/>
                            <Route path = {`${match.path}/college-list/:bin/teachers`} component={ SystemCollegeTeachers } exact/>
                            <Route path = {`${match.path}/college-list/:bin/teachers/:iin`} component={ SystemCollegePredmets } exact/>
                            <Route path = {`${match.path}/college-list/:bin/teachers/:iin/journal/:journalId`} component={ SystemCollegeJournal } exact/>
                        </div>
                    </div>
                </div>
                <footer className="footer footer-static footer-light">
                    <p className="clearfix mb-0">
                        <span className="float-left d-inline-block">2020 © DASH COLLEGE.EDU</span>
                        <span className="float-right d-sm-inline-block d-none">
                            Powered by
                            <a className="text-uppercase" href="https://mediana.kz" rel="noopener noreferrer" target="_blank">Mediana.kz</a>
                        </span>
                    </p>
                </footer>
            </>
        );
    }
}

const mapStateToProps = ({ userInfo, pageInfo, loadingState }) => {
    return {
        userInfo: userInfo,
        pageInfo: pageInfo,
        loadingState: loadingState
    }
}

const mapDispatchToProps = {
    userSignedIn,
    changedLoadingState
}

export default withAPI()(connect(mapStateToProps, mapDispatchToProps)(withRouter(System)))