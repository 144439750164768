export default class API {
    _baseURL = 'http://dash.college.edu.kz/api';

    async fetchAPI(url, method, body = {}){
        const options = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            ...method === "POST" && { body: JSON.stringify(body) }
        }
        const res = await fetch(`${this._baseURL}${url}`, options);

        if(!res.ok) {
            throw new Error(JSON.stringify({
                message: `Could not fetch ${url}` + `, received ${res.status}`,
                status: res.status
            }));
        }
        return await res.json();
    }

    checkUserAuth = async () => {
        const res = this.fetchAPI('/checkUserAuth', 'GET');
        return res
    }

    signIn = async (iin, password) => {
        const res = this.fetchAPI('/signIn', 'POST', {
            iin: iin,
            password: password
        });
        return res
    }

    signOut = async () => {
        const res = this.fetchAPI('/signOut', 'GET');
        return res
    }
    
    getCollegeList = async () => {
        const res = this.fetchAPI('/listOfColleges', 'GET');
        return res
    }

    getStatistics = async () => {
        const res = this.fetchAPI('/statistics', 'GET');
        return res
    }

    getCollegeInfo = async (bin) => {
        const res = this.fetchAPI(`/college/${bin}`, 'GET');
        return res
    }

    getCollegeGroupList = async (bin) => {
        const res = this.fetchAPI(`/groupsOfCollege/${bin}`, 'GET');
        return res
    }

    getGroupStudentList = async (bin, groupId) => {
        const res = this.fetchAPI(`/studentsOfGroup/${bin}/${groupId}`, 'GET');
        return res
    }

    getCollegeTeacherList = async (bin) => {
        const res = this.fetchAPI(`/teachersOfCollege/${bin}`, 'GET');
        return res
    }

    getTeacherPredmetList = async (bin, iin) => {
        const res = this.fetchAPI(`/predmetsOfTeachers/${bin}/${iin}`, 'GET');
        return res
    }

    getStudentsList = async (bin, groupId, action) => {
        const res = this.fetchAPI(`/studentsOfCourse/${bin}/${groupId}/${action}`, 'GET');
        return res
    }
}