import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { changedPageInfo, changedLoadingState } from '../../../../store/actions'
import { withAPI } from '../../../../hoc'

class SystemCollegeList extends React.Component {
    state ={
        collegeList: []
    }

    componentDidMount(){
        const { API, changedPageInfo, changedLoadingState } = this.props
        changedLoadingState(true);
        API.getCollegeList()
        .then(res => {
            this.setState({
                collegeList: res
            })
            changedLoadingState(false);
        })
        
         
        changedPageInfo({
            menuIndex: 2,
            title: 'Список колледжей'
        })
    }

    render(){
        const { collegeList } = this.state
        const { match } = this.props
        return(
            <>
                <div className="row">
                    <div className="col-12">
                        <section id="table-Marketing">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Список колледжей</h5>
                                    <div className="heading-elements">
                                        <ul className="list-inline">
                                            <li><span className="badge badge-pill badge-light-success">{ collegeList.length } активных</span></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="table-responsive">
                                    <table id="table-marketing-campaigns" className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>БИН</th>
                                                <th>Название</th>
                                                <th>Регион</th>
                                                <th>Адрес</th>
                                                <th>Посмотреть</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                               collegeList.map((value, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{ value.bin }</td>
                                                            <td>{ value.name }</td>
                                                            <td>{ value.region }</td>
                                                            <td>{ value.adress }</td>
                                                            <td><NavLink to={`${match.path}/${value.bin}`} className="btn btn-icon btn-light-primary btn-sm"><i className="bx bxs-show"></i></NavLink></td>
                                                        </tr>
                                                    )
                                               }) 
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        userInfo: userInfo
    }
}

const mapDispatchToProps = {
    changedPageInfo,
    changedLoadingState
}

export default withAPI()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemCollegeList)))